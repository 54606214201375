var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"d-flex justify-content-end mb-3"},[_c('b-button',{staticClass:"btn btn-success",on:{"click":_vm.backToTimesheet}},[_c('i',{staticClass:"mdi mdi-arrow-left mr-2"}),_vm._v(" Return ")])],1),(!_vm.isPresent)?_c('div',{staticClass:"alert alert-warning"},[_vm._v(" Please note, you will be marked as absent for the timesheet entry on "+_vm._s(_vm.timesheetDate.toDateString())+" ")]):_vm._e(),(_vm.apiBusy)?_c('scale-loader'):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"p-3 bg-light mb-4 d-flex align-items-center justify-content-between"},[_c('h5',{staticClass:"font-size-14 mb-0"},[_vm._v("File Entry")]),(_vm.isPresent)?_c('b-button',{staticClass:"btn btn-warning mr-3",on:{"click":_vm.setAbsent}},[_vm._v(" Set Absent ")]):_c('b-button',{staticClass:"btn btn-info mr-3",on:{"click":_vm.setPresent}},[_vm._v(" Set Present ")])],1),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"start"}},[_vm._v(" Start Time "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.start),expression:"start"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.start.$error,
                    },attrs:{"id":"start","type":"time","disabled":!_vm.isPresent},domProps:{"value":(_vm.start)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.start=$event.target.value},_vm.getTimeDiff]}}),(_vm.submitted && _vm.$v.start.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.start.required)?_c('span',[_vm._v(" Start time is required. ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"end"}},[_vm._v(" End Time "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.end),expression:"end"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.end.$error,
                    },attrs:{"id":"end","type":"time","disabled":!_vm.isPresent},domProps:{"value":(_vm.end)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.end=$event.target.value},_vm.getTimeDiff]}}),(_vm.submitted && _vm.$v.end.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.end.required)?_c('span',[_vm._v(" End time is required. ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"duration"}},[_vm._v(" Duration (hrs) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.duration),expression:"duration"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitted && _vm.$v.duration.$error,
                    },attrs:{"id":"duration","type":"number","disabled":!_vm.isPresent,"step":".05"},domProps:{"value":(_vm.duration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.duration=$event.target.value}}}),(_vm.submitted && _vm.$v.duration.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.duration.required)?_c('span',[_vm._v(" Duration is required. ")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"form-group"},[(!_vm.submitting)?_c('b-button',{staticClass:"btn btn-success btn-block mt-4",attrs:{"type":"submit"}},[(_vm.update)?_c('span',[_vm._v("Update")]):_c('span',[_vm._v(" Submit ")])]):_c('b-button',{staticClass:"btn btn-success btn-block mt-4",attrs:{"disabled":"","type":"submit"}},[(_vm.update)?_c('span',[_vm._v("Updating...")]):_c('span',[_vm._v("Submitting...")])])],1)])])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"p-3 bg-light mb-4"},[_c('h5',{staticClass:"font-size-14 mb-0"},[_vm._v("Timesheet Data")])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("Pay Period Day")]),_c('p',[_c('span',[_vm._v(_vm._s(_vm.timesheetDate.getDate()))]),_c('span',[_vm._v("-")]),_c('span',[_vm._v(_vm._s(_vm._f("getMonth")(_vm.timesheetDate.getMonth())))])])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("Date/Day")]),_c('p',[_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("getDay")(_vm.timesheetDate.getDay()))+" ")])])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("Start Time")]),(
                _vm.timesheetDate.getDay() === 0 || _vm.timesheetDate.getDay() === 6
              )?_c('p',[_vm._v(" WEEKEND ")]):_c('p',[_vm._v(_vm._s(this.tConvert(_vm.start)))])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("Finish Time")]),(
                _vm.timesheetDate.getDay() === 0 || _vm.timesheetDate.getDay() === 6
              )?_c('p',[_vm._v(" WEEKEND ")]):_c('p',[_vm._v(_vm._s(this.tConvert(_vm.end)))])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("Break Hours")]),(_vm.timesheetDate.getDay() === 5)?_c('p',[_vm._v(" "+_vm._s(_vm.breakDuration)+" mins ")]):(
                _vm.timesheetDate.getDay() === 0 || _vm.timesheetDate.getDay() === 6
              )?_c('p',[_vm._v(" WEEKEND ")]):_c('p',[_vm._v(_vm._s(_vm.breakDuration)+" mins")])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("Hours Worked")]),(
                _vm.timesheetDate.getDay() === 0 || _vm.timesheetDate.getDay() === 6
              )?_c('p',[_vm._v(" WEEKEND ")]):_c('p',[_vm._v(_vm._s(_vm.duration)+" hrs")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }